<template>
    <div class="login-layout__content">
        <form class="login-layout__form application-input-theme-login" @submit.prevent="submit">
            <!-- Form title -->
            <div class="login-layout__header">
                {{ $t("login.index") }}

                <AppSwitchLang/>
            </div>

            <!-- Form body -->
            <div class="application-input-wrap login-layout__field">
                <div class="application-input-title">{{ $t("login.form.enterLogin") }}</div>
                <AppInput
                    v-model="model.username"
                    class="application-input"
                    :error="isShowInputError || ($v.model.username.$dirty && $v.model.username.$invalid)"
                    :placeholder="$t('login.form.enterLoginPlaceholder')"
                    :aria-label="$t('login.form.enterLoginAriaLabel')"
                    aria-describedby="username"
                    autocomplete="username"
                />
            </div>

            <div class="application-input-wrap login-layout__field">
                <div class="application-input-title">{{ $t("login.form.enterPassword") }}</div>
                <label class="application-input password-input">
                    <AppInput
                        v-model="model.password"
                        :type="passwordVisible ? 'text' : 'password'"
                        autocomplete="current-password"
                        :error="isShowInputError || $v.model.password.$anyError || lockTimes < 3"
                        :placeholder="$t('login.form.enterPasswordPlaceholder')"
                        :aria-label="$t('login.form.enterLoginAriaLabel')"
                        aria-describedby="password"
                    />
                    <div class="password-input__button" @click="togglePasswordVisible">
                        <AppIcon :icon="passwordVisible ? 'eye-hide' : 'eye'" :key="passwordVisible + 1"/>
                    </div>
                </label>
                <div v-if="lockTimes < 3" class="application-input-error">
                    {{
                        $t("errors.youHaveSomeAttempts", {
                            lockTimes,
                            lockTimesWord: getNoun(lockTimes, $t('login.form.attemptOne'), $t('login.form.attemptTwo'), $t('login.form.attemptThree'))
                        })
                    }}
                </div>
            </div>

            <div class="application-input-wrap login-layout__field">
                <div class="application-input-title">{{ $t("login.form.enterWorkPhoneNumber") }}</div>
                <AppInput
                    v-model="model.phone"
                    :max="12"
                    v-mask="'############'"
                    :error="isShowInputError || $v.model.phone.$anyError"
                    :placeholder="$t('login.form.enterWorkPhoneNumberPlaceholder')"
                    :aria-label="$t('login.form.enterWorkPhoneNumberAriaLabel')" aria-describedby="phone"
                />
                <div v-if="$v.model.phone.$anyError" class="application-input-error">
                    {{ $t('errors.enteredPasswordOrPhoneNumberOrLoginIncorrect') }}
                </div>
            </div>

            <!-- Form actions -->
            <AppButton class="login-layout__button" :disabled="isLoading" type="submit">
                {{ isLoading ? $t('common.awaiting') : $t('common.letsGo') }}
            </AppButton>

        </form>
    </div>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import {CommonMixin} from "@/mixins/Common.mixin";

export default {
    emits: ["update:form", "on:submit", "toggle:passwordVisible"],
    mixins: [CommonMixin],
    model: {
        prop: "form",
        event: "update:form"
    },
    components: {
        AppIcon: () => import("@/components/partials/AppIcon.vue"),
        AppButton: () => import("@/components/partials/AppButton.vue"),
        AppSwitchLang: () => import("@/components/partials/AppSwitchLang.vue"),
        AppInput: () => import("@/components/partials/AppInput.vue")
    },
    props: {
        passwordVisible: Boolean,
        isLoading: Boolean,
        lockTimes: Number,
        isShowInputError: Boolean,
        form: {
            type: Object,
            required: true,
            default: () => ({
                username: "",
                phone: "",
                password: "",
            })
        }
    },
    computed: {
        model: {
            get() {
                return this.form
            },
            set($val) {
                this.$emit("update:form", $val)
            }
        }
    },
    methods: {
        togglePasswordVisible() {
            this.$emit("toggle:passwordVisible")
        },
        submit() {
            this.$v.model.$touch();

            if (this.$v.$anyError === false) {
                this.$emit("on:submit")
            }
        }
    },
    validations: {
        model: {
            password: {
                required,
                minLength: minLength(3)
            },
            username: {
                required,
                minLength: minLength(4)
            },
            phone: {
                required,
                minLength: minLength(4)
            }
        }
    },
}
</script>
